<template>
  <div class="container">
    <div class="main">
      <yanzheng />
      <div class="zezhao" v-if="zezhao||weihu">
        <div class="zezhao_tip" v-if="zezhao">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}现已关盘</h2>
            <p>下期开盘时间为</p>
            <p class="green">{{kaipanTime}}</p>
          </div>
        </div>
        <div class="zezhao_tip" v-if="weihu">
          <section class="zz-header">温馨提示</section>
          <div class="zz_content">
            <section style="height:1px"></section>
            <img src="../../assets/closewarn.png" class="closewarn" alt="">
            <h2>{{currentGame.Gamename}}正在维护</h2>

          </div>
        </div>

      </div>
      <top-timer></top-timer>
     <!--  <div class="cate cm">
        <span class="">第一球</span>
        <span class="">第二球</span>
        <span class="">第三球</span>
        <span class="">第四球</span>
        <span class="selected">第五球</span> 
      </div>-->
      <div class="ft-dsn">
        <!-- 左边 -->
        <div class="w-left" data-attr="">
          <div class="fth">
            <span class="td row1 left">三门</span>
            <span class="td row3 tongtitle">通</span>
            <span class="td row1 left">正</span>
          </div>
          <ul class="select-ul">
            <template v-if="rowsData[0].arr&&rowsData[0].arr.length>0">

              <li class="items " @click="setCurrentSelected(item)" :class="{selected:item.selected}" v-for="item in rowsData[0].arr" :key="item.ResultID">
                <div class="n">
                  <b v-for="b in item.label.barr" :key="item.ResultID+b">{{ b }}</b>

                </div>
                <div class="c">{{ item.label.type }}</div>
                <div class="r">{{ item.Odds }}</div>
                <div class="p">
                  <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                </div>
              </li>
            </template>
          </ul>
        </div>
      <div style="width:10px"></div>
        <!-- 右边 -->
        <div class="w-right">
          <ul class="ul-row1 ulh">
            <template v-for="(item,i) in rowsData[1].arr">
              <li class="items" v-if="i<=4" @click="setCurrentSelected(item)" :class="{selected:item.selected}" :key="item.ResultID">

                <div v-if="item.label.type" class="t">{{ item.label.type }}</div>
                <div class="n"><b v-for="b in item.label.barr" :key="item.ResultID+b">{{ b }}</b></div>
                <div class="r">{{ item.Odds }}</div>
                <div class="p">
                  <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                </div>

              </li>
            </template>
          </ul>
          <div class="b-svg">

            <div class="row1">
              <template v-for="(item,i) in rowsData[2].arr">
                <ul v-if="i<=2" class="ul-row1 ul2 ulh2" :key="item.ResultID">
                  <li class="items" @click="setCurrentSelected(item)" :class="{selected:item.selected}">
                    <div class="n"><b v-for="b in item.label.barr" :key="item.ResultID+b">{{ b }}</b></div>
                    <div class="r">{{ item.Odds }}</div>
                    <div class="p">
                      <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                    </div>
                  </li>
                </ul>
              </template>
            </div>
            <div class="row3 f-abso">
              <div class="f-svgbox">
                <div class="f-svg">
                  <svg class="svg-wrapper" height="100%" width="100%" viewBox="0 0 100 100" preserveAspectRatio="none">
                    <path class="top" v-for="item in filterRowsData(3,4)" :class="{selected:item.selected}" @click="setCurrentSelected(item)" @mouseenter="MouseEnter($event,'top')" :key="item.ResultID+'top'" @mouseleave="MouseLeave($event,'top')" data-class="top" d="M0 0 L50 50 L100 0 Z" fill="#aad8c5" stroke="#b9c2cb"></path>
                    <path class="right" v-for="item in filterRowsData(3,3)" :class="{selected:item.selected}" @click="setCurrentSelected(item)" @mouseenter="MouseEnter($event,'right')" :key="item.ResultID+'right'" @mouseleave="MouseLeave($event,'right')" data-class="right" d="M50 50 L100 100 L100 0 Z" fill="#aad8c5" stroke="#b9c2cb"></path>
                    <path class="bottom" v-for="item in filterRowsData(3,2)" :class="{selected:item.selected}" @click="setCurrentSelected(item)" @mouseenter="MouseEnter($event,'bottom')" :key="item.ResultID+'bottom'" @mouseleave="MouseLeave($event,'bottom')" data-class="bottom" d="M0 100 L100 100 L50 50 Z" fill="#aad8c5" stroke="#b9c2cb"></path>
                    <path class="left" v-for="item in filterRowsData(3,5)" :class="{selected:item.selected}" @click="setCurrentSelected(item)" @mouseenter="MouseEnter($event,'left')" :key="item.ResultID+'left'" @mouseleave="MouseLeave($event,'left')" data-class="left" d="M0 100 L50 50 L0 0 Z" fill="#aad8c5" stroke="#b9c2cb"></path>
                  </svg>
                </div>
                <!-- 上 -->
                <div class="s-item a items svg-hover " v-for="item in filterRowsData(3,4)" :class="{selected:item.selected}" @click="setCurrentSelected(item)" :key="item.ResultID" @mouseenter="MouseEnter($event,'top')" @mouseleave="MouseLeave($event,'top')" data-class="top">
                  <div>
                    <b v-for="b in item.label.barr" :key="item.ResultID+b" class="n">{{ b }}</b>
                    <em class="r">{{ item.Odds }}</em>
                  </div>
                  <div class="p">
                    <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                  </div>
                </div>
                <!-- 下 -->
                <div data-class="bottom" v-for="item in filterRowsData(3,2)" :class="{selected:item.selected}" @click="setCurrentSelected(item)" @mouseenter="MouseEnter($event,'bottom')" @mouseleave="MouseLeave($event,'bottom')" class="s-item b items svg-hover" :key="item.ResultID">
                  <div>
                    <b v-for="b in item.label.barr" :key="item.ResultID+b" class="n">{{ b }}</b>
                    <em class="r">{{ item.Odds }}</em>
                  </div>
                  <div class="p">
                    <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                  </div>
                </div>

                <!-- 左 -->
                <div data-class="left" v-for="item in filterRowsData(3,5)" :class="{selected:item.selected}" @click="setCurrentSelected(item)" :key="item.ResultID" @mouseenter="MouseEnter($event,'left')" @mouseleave="MouseLeave($event,'left')" class="s-item c items svg-hover">
                  <div>
                    <b v-for="b in item.label.barr" :key="item.ResultID+b" class="n">{{ b }}</b><br />
                    <em class="r">{{ item.Odds }}</em>
                  </div>
                  <div class="p">
                    <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                  </div>
                </div>
                <!-- 右 -->
                <div data-class="right" v-for="item in filterRowsData(3,3)" :class="{selected:item.selected}" @click="setCurrentSelected(item)" :key="item.ResultID" @mouseenter="MouseEnter($event,'right')" @mouseleave="MouseLeave($event,'right')" class="s-item d items svg-hover">
                  <div>
                    <b v-for="b in item.label.barr" :key="item.ResultID+b" class="n">{{ b }}</b><br />
                    <em class="r">{{ item.Odds }}</em>
                  </div>
                  <div class="p">
                    <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                  </div>
                </div>
                <div class="s-itemds">
                  <ul class="ul-row1 ul3">
                    <template v-for="(item,i) in rowsData[3].arr">
                      <li class="items" v-if="i<=1" @click="setCurrentSelected(item)" :class="{selected:item.selected}" :key="item.ResultID">
                        <div class="n"><b v-for="b in item.label.barr" :key="item.ResultID+b">{{ b }}</b></div>
                        <div class="r">{{ item.Odds }} </div>
                        <div class="p">
                          <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                        </div>
                      </li>
                    </template>
                  </ul>
                </div>
                <!-- ------------------- -->
              </div>
            </div>
            <div class="row1">
              <template v-for="(item,i) in rowsData[2].arr">
                <ul v-if="i>2" class="ul-row1 ul2 ulh2" :key="item.ResultID">
                  <li class="items" @click="setCurrentSelected(item)" :class="{selected:item.selected}">
                    <div class="n"><b v-for="b in item.label.barr" :key="item.ResultID+b">{{ b }}</b></div>
                    <div class="r">{{ item.Odds }}</div>
                    <div class="p">
                      <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                    </div>
                  </li>
                </ul>
              </template>
            </div>
            <!-- ------------------- -->
          </div>
          <ul class="ul-row1 ulh">
            <template v-for="(item,i) in rowsData[1].arr">
              <li v-if="i>4" @click="setCurrentSelected(item)" :class="{selected:item.selected}" class="items" :key="item.ResultID">
                <div v-if="item.label.type" class="t">{{ item.label.type }}</div>
                <div class="n"><b v-for="b in item.label.barr" :key="item.ResultID+b">{{ b }}</b></div>
                <div class="r">{{ item.Odds }}</div>
                <div class="p">
                  <input type="text" @click.stop="showOrHideCheck($event, item)" v-model="item.money">
                </div>
              </li>

            </template>
          </ul>
        </div>
        <!-- ----------------------------------------- -->
      </div>
      <yushe />
      <div style="height:10px"></div>

      <!-- <div class="ft-dsn-toji">
        <h3>
          <span class="s">开奖号码</span>
          <span class="">单双</span>
          <span class="">大小</span>
        </h3>
        <ul>
          <li>
            <span class="red">1</span>
          </li>
          <li>
            <span class="blue">2</span>
          </li>
        </ul>

      </div> -->
    </div>
    <div class="other">
      <div class="zezhao" v-if="zezhao||weihu"></div>
      <div class="right-com">
        <div class="rc-tab">
          <span :class="changelongActive == '1' ? 'selected' : ''">统计</span>
          <!-- <span :class="changelongActive == '2' ? 'selected' : ''" @click="setLongTab('2')">全部长龙</span> -->
        </div>
        <div class="ft-dsn-toji">

          <div class="thd th">
            <span>号码</span>
            <span>1 番</span>
            <span>2 番</span>
            <span>3 番</span>
            <span>4 番</span>
          </div>
          <div class="thd">
            <span>出 球 率</span>
            <span>{{ chuqiusum.fan1 }}</span>
            <span>{{ chuqiusum.fan2 }}</span>
            <span>{{ chuqiusum.fan3 }}</span>
            <span>{{ chuqiusum.fan4 }}</span>
          </div>
          <div class="thd">
            <span>无出期数</span>
            <span>{{ wuqishusum.fan1 }}</span>
            <span>{{ wuqishusum.fan2 }}</span>
            <span>{{ wuqishusum.fan3 }}</span>
            <span>{{ wuqishusum.fan4 }}</span>
          </div>
        </div>
        <div class="roadmap">
          <table class="ltbinfo">
            <tbody>
              <tr>
                <th>摊路</th>
              </tr>
            </tbody>
          </table>
          <table colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <tr v-for="(item,index) in lutu1.arr" :key="index+10">
                <td v-for="(ele,i) in item" :class="'color'+ele" :key="ele+i">
                  <span  >{{ele}}</span>

                </td>

              </tr>
            </tbody>
          </table>
          <!-- <table class="ltbinfo">
            <tbody>
              <tr>
                <th>大小</th>
              </tr>
            </tbody>
          </table>
          <table colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <tr v-for="(item,index) in lutu2.arr" :key="index+20">
                <td v-for="(ele,i) in item" :key="ele+i">
                  <span :class="fontColor[ele]">{{ele}}</span>

                </td>

              </tr>
            </tbody>
          </table> -->
          <table class="ltbinfo">
            <tbody>
              <tr>
                <th>单双</th>
              </tr>
            </tbody>
          </table>
          <table colspan="0" cellspan="0" class="lttab" style="display: table;">
            <tbody>
              <tr v-for="(item,index) in lutu3.arr" :key="index+30">
                <td v-for="(ele,i) in item" :key="ele+i">
                  <span :class="fontColor[ele]">{{ele}}</span>

                </td>

              </tr>
            </tbody>
          </table>
     

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import yanzheng from "../yanzheng.vue";
import topTimer from "../mainHeader.vue";
import yushe from "../yushe.vue";
import submitTK from "../submitTK";
import mixins from "../mixins/mainMixins";
import { mapState } from "vuex";
export default {
  name: "fantanzsp",
  props: [""],
  data() {
    return {
      changelongActive: "1",
      groupnames: "fantan",
      rowsData: [
        { title: "left", arr: [] },
        { title: "shangxiahang", arr: [] },
        { title: "zuoyouhang", arr: [] },
        { title: "fan", arr: [] },
      ],
      labelFan: [
        { barr: ["单"] },
        { barr: ["双"] },
        { barr: ["1番"] },
        { barr: ["2番"] },
        { barr: ["3番"] },
        { barr: ["4番"] },
      ],
      labelzuoyouhgang: [
        { id: 3853, type: "", barr: ["4", "念", "3"] },
        { id: 3852, type: "", barr: ["4", "念", "2"] },
        { id: 3851, type: "", barr: ["4", "念", "1"] },
        { id: 3846, type: "", barr: ["2", "念", "3"] },
        { id: 3847, type: "", barr: ["2", "念", "4"] },
        { id: 3845, type: "", barr: ["2", "念", "1"] },
      ],
      labelshangxiaHang: [
        { id: 3856, type: "角", barr: ["3", "4"] },
        { id: 3850, type: "", barr: ["3", "念", "4"] },
        { id: 3848, type: "", barr: ["3", "念", "1"] },
        { id: 3849, type: "", barr: ["3", "念", "2"] },
        { id: 3855, type: "角", barr: ["2", "3"] },
        { id: 3857, type: "角", barr: ["4", "1"] },
        { id: 3844, type: "", barr: ["1", "念", "4"] },
        { id: 3843, type: "", barr: ["1", "念", "3"] },
        { id: 3842, type: "", barr: ["1", "念", "2"] },
        { id: 3854, type: "角", barr: ["1", "2"] },
      ],
      labelArr1: [
        { id: 3874, type: "三门", barr: ["2", "3", "4"] },
        { id: 3858, type: "一通", barr: ["2", "3"] },
        { id: 3859, type: "一通", barr: ["2", "4"] },
        { id: 3860, type: "一通", barr: ["3", "4"] },
        {id:21757,type:'一通',barr:['2']}, // new
        {id:21758 ,type:'一通',barr:['4']}, // new
        { id: 3870, type: "正", barr: ["1"] },
        { id: 3875, type: "三门", barr: ["1", "3", "4"] },
        { id: 3861, type: "二通", barr: ["1", "3"] },
        { id: 3862, type: "二通", barr: ["1", "4"] },
        { id: 3863, type: "二通", barr: ["3", "4"] },
        {id:21755,type:'二通',barr:['1']}, // new
        {id:21756,type:'二通',barr:['3']}, // new
        { id: 3871, type: "正", barr: ["2"] },
        { id: 3876, type: "三门", barr: ["1", "2", "4"] },
        { id: 3864, type: "三通", barr: ["1", "2"] },
        { id: 3865, type: "三通", barr: ["1", "4"] },
        { id: 3866, type: "三通", barr: ["2", "4"] },
        {id:21753,type:'三通',barr:['2']}, // new
        {id:21754,type:'三通',barr:['4']}, // new
        { id: 3872, type: "正", barr: ["3"] },
        { id: 3877, type: "三门", barr: ["1", "2", "3"] },
        { id: 3867, type: "四通", barr: ["1", "2"] },
        { id: 3868, type: "四通", barr: ["1", "3"] },
        { id: 3869, type: "四通", barr: ["2", "3"] },
        {id:21751,type:'四通',barr:['1']}, // new
        {id:21752,type:'四通',barr:['3']}, // new
        { id: 3873, type: "正", barr: ["4"] },
      ],
      kjlist: [],
      chuqiusum: {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      },
      wuqishusum: {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      },
      // 球号
      lutu1: {
        arr: [[], [], [], [], []],
      },
      // 大小
      lutu2: {
        arr: [[], [], [], [], []],
      },
      // 单双
      lutu3: {
        arr: [[], [], [], [], []],
      },
      ltArr: [],
    };
  },
  mixins: [mixins],
  components: {
    yushe,
    topTimer,
    submitTK,
    yanzheng,
  },
  created() {
    this.getHisList();
  },
  computed: {},
  watch: {
    rowsData: {
      deep: true,
      handler(val) {
        this.$nextTick(() => {
          let arr = [];
          val.forEach((item) => {
            item.arr.forEach((ele) => {
              if (ele.money) {
                let obj = {
                  label: ele.label.barr.join(""),
                  Odds: ele.Odds,
                  title: ele.label.type ? ele.label.type : "",
                  id: ele.ResultID,
                  money: Number(ele.money),
                };
                arr.push(obj);
              }
            });
          });

          // console.log(arr, "arrrr");
          if (arr.length > 0) {
            this.$store.commit("setXZList", arr);
          } else {
            this.$store.commit("setXZList", []);
          }
        });
      },
    },
    oddData1: {
      immediate: true,
      deep: true,
      handler(resAArr) {
     
        // console.log(resAArr,456)
        // 左边  数据
        let leftIds = [];
        this.labelArr1.forEach((item) => {
          leftIds.push(item.id);
        });
        let arr0 = this.ddFilterData1(resAArr, leftIds, this.labelArr1);

        this.rowsData[0].arr = arr0;
        //  右边  上面和下面 那两行
        let shangxiaIds = [];
        this.labelshangxiaHang.forEach((item) => {
          shangxiaIds.push(item.id);
        });
        let arr1 = this.ddFilterData1(
          resAArr,
          shangxiaIds,
          this.labelshangxiaHang
        );
        this.rowsData[1].arr = arr1;
        // 右边 左边和右边 那两行
        let zuoyouIds = [];
        this.labelzuoyouhgang.forEach((item) => {
          zuoyouIds.push(item.id);
        });
        let arr2 = this.ddFilterData1(
          resAArr,
          zuoyouIds,
          this.labelzuoyouhgang
        );
        this.rowsData[2].arr = arr2;
        //  内部番摊
        let arr3 = this.ddFilterData1(
          resAArr,
          [3836, 3837, 3838, 3839, 3840, 3841],
          this.labelFan
        );
        this.rowsData[3].arr = arr3;
      },
    },
  },
  beforeMount() {},

  mounted() {
    //上
    this.$on("top-hovered", () => {
      const DOMS = document.querySelectorAll('[data-class="top"]');
      DOMS.forEach((top) => {
        top.classList.add("special-effect");
      });
    });
    this.$on("top-unhovered", () => {
      const DOMS = document.querySelectorAll('[data-class="top"]');
      DOMS.forEach((top) => {
        top.classList.remove("special-effect");
      });
    });
    //下
    this.$on("bottom-hovered", () => {
      const DOMS = document.querySelectorAll('[data-class="bottom"]');
      DOMS.forEach((top) => {
        top.classList.add("special-effect");
      });
    });
    this.$on("bottom-unhovered", () => {
      const DOMS = document.querySelectorAll('[data-class="bottom"]');
      DOMS.forEach((top) => {
        top.classList.remove("special-effect");
      });
    });
    //左
    this.$on("left-hovered", () => {
      const DOMS = document.querySelectorAll('[data-class="left"]');
      DOMS.forEach((top) => {
        top.classList.add("special-effect");
      });
    });
    this.$on("left-unhovered", () => {
      const DOMS = document.querySelectorAll('[data-class="left"]');
      DOMS.forEach((top) => {
        top.classList.remove("special-effect");
      });
    });
    //右
    this.$on("right-hovered", () => {
      const DOMS = document.querySelectorAll('[data-class="right"]');
      DOMS.forEach((top) => {
        top.classList.add("special-effect");
      });
    });
    this.$on("right-unhovered", () => {
      const DOMS = document.querySelectorAll('[data-class="right"]');
      DOMS.forEach((top) => {
        top.classList.remove("special-effect");
      });
    });
  },

  methods: {
    getKJHis() {
      (this.lutu1 = {
        arr: [[], [], [], [], []],
      }),
        // 大小
        (this.lutu2 = {
          arr: [[], [], [], [], []],
        }),
        // 单双
        (this.lutu3 = {
          arr: [[], [], [], [], []],
        });
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        roomeng: this.currentGame.roomeng,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
        pagenum: 1,
        pagecount: 50,
      };
      this.$http.post("getlotteryhis", obj).then((res) => {
        if (res.Status) {
          let resObj = JSON.parse(res.Msg);
          if (resObj.data) {
            let arr1 = [[]],
              arr2 = [[]],
              arr3 = [[]];
            let list = resObj.data;
            list.reverse()
            list.forEach((element) => {
              if(element.Opencode.includes("+")){
                let jiaArr=element.Opencode.split('+');
              element.codeArr=jiaArr[0].split(",")
              element.fan=jiaArr[1]
              }else{
                element.codeArr = element.Opencode.split(",");
              }
             let obj=this.fantangameopencode(element.codeArr)
              let sum = obj.sum;
            
              let yu='';
              if(element.Opencode.includes("+")){
                yu=element.fan
              }else{
                yu = Number(sum) % 4;
              yu = yu == 0 ? 4 : yu;
              }
           
              let dx = obj.dx;
              let ds = Number(element.fan)%2==0?'双':'单'
              // 球号
              this.initlutuData(arr1, yu);
              // 大小
              this.initlutuData(arr2, dx);
              //  单双
              this.initlutuData(arr3, ds);
            });
            this.buquanArr1(arr1, this.lutu1.arr);
            this.buquanArr1(arr2, this.lutu2.arr);
            this.buquanArr1(arr3, this.lutu3.arr);
            // console.log(this.lutu1.arr);
          } else {
            (this.lutu1 = {
              arr: [[], [], [], [], []],
            }),
              // 大小
              (this.lutu2 = {
                arr: [[], [], [], [], []],
              }),
              // 单双
              (this.lutu3 = {
                arr: [[], [], [], [], []],
              });
          }
        } else {
          (this.lutu1 = {
            arr: [[], [], [], [], []],
          }),
            // 大小
            (this.lutu2 = {
              arr: [[], [], [], [], []],
            }),
            // 单双
            (this.lutu3 = {
              arr: [[], [], [], [], []],
            });
        }
      });
    },
    getHisList() {
      this.chuqiusum = {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      };
      this.wuqishusum = {
        fan1: 0,
        fan2: 0,
        fan3: 0,
        fan4: 0,
      };
      let obj = {
        uuid: this.userInfo.Uuid,
        sid: this.userInfo.Sid,
        begindate: this.splitDate(this.getBeforeDate(0)),
        enddate: this.splitDate(this.getBeforeDate(0)),
        roomeng: this.currentGame.roomeng,
        pagenum: 1,
        pagecount: 1000,
      };
      this.$http
        .post("getlotteryhis", obj)
        .then((res) => {
          if (res.Status) {
            let resObj = JSON.parse(res.Msg);
            this.kjlist = resObj.data;
            this.kjlist.reverse();
            this.kjlist.forEach((item) => {
              if(item.Opencode.includes("+")){
                let jiaArr=item.Opencode.split('+');
              item.codeArr=jiaArr[0].split(",")
              item.fan=jiaArr[1]
              }else{
                item.codeArr = item.Opencode.split(",");
              }
              let obj=this.fantangameopencode( item.codeArr)
              let sum = obj.sum;
            
              let yu='',fan=''
              if(item.Opencode.includes("+")){
               fan=item.fan
              }else{
                yu = Number(sum) % 4;
               fan = yu == 0 ? 4 : yu;
              }
          
              for (const key in this.chuqiusum) {
                if (key[3] == fan) {
                  this.chuqiusum[key]++;
                  this.wuqishusum[key] = 0;
                }
                if (key[3] != fan) {
                  this.wuqishusum[key]++;
                }
              }
            });
          } else {
            this.$message.error(res.Msg);
          }
        })
        .catch((err) => {});
    },
    filterRowsData(index, i) {
      return this.rowsData[index].arr.filter((item, elei) => {
        return elei == i;
      });
    },
    MouseEnter(e, str) {
      this.$emit(str + "-hovered");
    },
    MouseLeave(e, str) {
      this.$emit(str + "-unhovered");
    },
  },
};
</script>
<style scoped>
.r{
  font-weight: bold;
}
.roadmap table th{
  padding: 2px;
}
.roadmap table td {
  height: 17.8px;
}
.ft-dsn .w-left .n b:last-child,
.ft-dsn .w-right .n b:last-child {
  margin-right: 0;
}
.ft-dsn .ul-row1.ul3 li {
  width: 50%;
  height: 100%;
}
.ft-dsn .f-abso .f-svgbox .s-itemds li {
  background: #ecf4fe;
}
.ft-dsn .f-abso .f-svgbox .s-itemds {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 50%;
  height: 50%;
  border: 1px solid #b9c2cb;
}

.ft-dsn .f-abso .f-svgbox .s-item .n {
  color: #1a9f49;
}
.ft-dsn .f-abso .f-svgbox .s-item > div {
  width: 100%;
  text-align: center;
}
.ft-dsn .f-abso .f-svgbox .s-item {
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  height: 25%;
}

.ft-dsn .f-abso .f-svgbox .s-item.d {
  right: 11px;
}

.ft-dsn .f-abso .f-svgbox .s-item.c,
.ft-dsn .f-abso .f-svgbox .s-item.d {
  z-index: 1;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 20%;
}
.ft-dsn .f-abso .f-svgbox .s-item.c {
  left: 11px;
}

.ft-dsn .f-abso .f-svgbox .s-item.b {
  bottom: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 50px;
}

.ft-dsn .f-abso .f-svgbox .s-item.a {
  z-index: 1;
  top: 4px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 50px;
}
.ft-dsn .f-abso .f-svgbox .f-svg {
  height: 226px;
}
.ft-dsn .f-abso .f-svgbox {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 226px;
  padding: 0;
  background: #d7d7d7;
  border: 1px solid #b9c2cb;
  border-radius: 15px;
  overflow: hidden;
}

.ft-dsn .row3 {
  width: 60%;
}
.ft-dsn .f-abso {
  position: relative;
}
.ft-dsn .ul-row1.ul2 li {
  width: 100%;
}
.ft-dsn .ul-row1.ulh2 li {
  height: 76px;
}
.ft-dsn .b-svg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ft-dsn .w-left .t,
.ft-dsn .w-right .t {
  color: #ff4081;
  font-size: 17px;
  font-weight: 700;
  line-height: 18px;
}
.ft-dsn .ul-row1.ulh li {
  height: 90px;
}
.ft-dsn .w-right {
  background: #ecf4fe;
}
/* -------统计 */
.ft-dsn-toji h3 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 24px;
}
.ft-dsn-toji h3 span.s {
  font-weight: 700;
}
.ft-dsn-toji h3 span:first-child {
  border-left: none;
}
.ft-dsn-toji h3 span {
  width: 100%;
  line-height: 24px;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 13px;
}
.ft-dsn-toji .thd.th {
  background: #eee;
}
.ft-dsn-toji .thd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ft-dsn-toji ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ft-dsn-toji ul li {
  width: 2.5%;
  min-height: 50px;
}
.ft-dsn-toji .thd span:first-child {
  width: 20%;
}
.ft-dsn-toji .thd span {
  width: 20%;
  text-align: center;
  line-height: 18.5px;
}
.ft-dsn-toji ul li span {
  display: block;
  line-height: 20px;
  text-align: center;
}
.ft-dsn-toji ul li span.blue {
  color: #00f;
}
.ft-dsn-toji ul li span.red {
  color: red;
}
/* -------三个皮肤的  */
.skin-blue .ft-dsn-toji h3 span {
  border-left: 1px solid #b9c2cb;
}
.skin-red .ft-dsn-toji h3 span {
  border-left: 1px solid #e6b3be;
}
.skin-brown .ft-dsn-toji h3 span {
  border-left: 1px solid #d8c295;
}
.skin-brown .ft-dsn-toji h3 span:nth-child(2) {
  border-left: 1px solid #d8c295;
}
.skin-brown .ft-dsn-toji .thd span,
.skin-brown .ft-dsn-toji ul li {
  border-left: 1px solid #d8c295;
  border-top: 1px solid #d8c295;
}
.skin-red .ft-dsn-toji .thd span,
.skin-red .ft-dsn-toji ul li {
  border-left: 1px solid #e6b3be;
  border-top: 1px solid #e6b3be;
}
.skin-blue .ft-dsn-toji .thd span,
.skin-blue .ft-dsn-toji ul li {
  border-left: 1px solid #b9c2cb;
  border-top: 1px solid #b9c2cb;
}
.skin-brown .ft-dsn-toji .thd span:first-child,
.skin-brown .ft-dsn-toji ul li:first-child {
  border-left: none;
}
.skin-red .ft-dsn-toji .thd span:first-child,
.skin-red .ft-dsn-toji ul li:first-child {
  border-left: none;
}
.skin-blue .ft-dsn-toji .thd span:first-child,
.skin-blue .ft-dsn-toji ul li:first-child {
  border-left: none;
}
.skin-brown .ft-dsn-toji h3 span.s {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#f4ecd9),
    color-stop(50%, #f4ecd9),
    color-stop(0, #e2dac8),
    to(#e2dac8)
  );
  background: linear-gradient(180deg, #f4ecd9, #f4ecd9 50%, #e2dac8 0, #e2dac8);
}
.skin-red .ft-dsn-toji h3 span.s {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(25%, #f5e2e2),
    color-stop(49%, #f5e2e2),
    color-stop(50%, #f1d6d4),
    to(#f1d6d4)
  );
  background: linear-gradient(
    180deg,
    #f5e2e2 25%,
    #f5e2e2 49%,
    #f1d6d4 50%,
    #f1d6d4
  );
}
.skin-blue .ft-dsn-toji h3 span.s {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#dee9f3),
    color-stop(50%, #dee9f3),
    color-stop(51%, #cfd9e3),
    to(#cfd9e3)
  );
  background: linear-gradient(
    180deg,
    #dee9f3 0,
    #dee9f3 50%,
    #cfd9e3 51%,
    #cfd9e3
  );
}
.skin-brown .ft-dsn-toji h3 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    color-stop(50%, #fff),
    color-stop(51%, #fdeee1),
    to(#fdeee1)
  );
  background: linear-gradient(180deg, #fff, #fff 50%, #fdeee1 51%, #fdeee1);
}
.skin-red .ft-dsn-toji h3 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    color-stop(50%, #fff),
    color-stop(51%, #ffefee),
    to(#ffefee)
  );
  background: linear-gradient(180deg, #fff, #fff 50%, #ffefee 51%, #ffefee);
}
.skin-blue .ft-dsn-toji h3 {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0, #fff),
    color-stop(50%, #fff),
    color-stop(51%, #fef4ea),
    to(#fef4ea)
  );
  background: linear-gradient(180deg, #fff, #fff 50%, #fef4ea 51%, #fef4ea);
}
.skin-brown .ft-dsn-toji {
  border: 1px solid #d8c295;
}
.skin-red .ft-dsn-toji {
  border: 1px solid #e6b3be;
}
.skin-blue .ft-dsn-toji {
  border: 1px solid #b9c2cb;
}
.skin-blue .ft-dsn .items.selected,
.skin-blue .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}
.special-effect {
  fill: #87c8ad;
  background: #87c8ad;
}
.skin-blue .ft-dsn .items.svg-hover:hover {
  background: #87c8ad !important;
}
path.selected {
  fill: #ffc214 !important;
}
.skin-red .ft-dsn .items.selected,
.skin-red .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}

.skin-red .ft-dsn .items.svg-hover:hover {
  background: #87c8ad !important;
}
.skin-brown .ft-dsn .items.selected,
.skin-brown .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}

.skin-brown .ft-dsn .items.svg-hover:hover {
  background: #87c8ad !important;
}
.skin-brown .ft-dsn .items:hover {
  background: #e2dac8 !important;
}
.skin-blue .ft-dsn .items:hover {
  background: #c3d9f1 !important;
}

.skin-red .ft-dsn .items:hover {
  background: #f6d3e4 !important;
}

.skin-blue .ft-dsn .items.selected,
.skin-blue .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}
.skin-brown .ft-dsn .items.selected,
.skin-brown .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}
.skin-red .ft-dsn .items.selected,
.skin-red .ft-dsn .items.selected:hover {
  background: #ffc214 !important;
}
/* ------------------------------------------------------------ */
.ft-dsn .w-left .p input,
.ft-dsn .w-right .p input {
  width: 70%;
  border-radius: 4px;
  border: 1px solid #bdc2c7;
  height: 15px;
  background: #fff;
  outline: none;
  text-align: center;
}
.ft-dsn .w-left .r,
.ft-dsn .w-right .r {
  color: red;
  font-family: Arial, Microsoft Yahei, Helvetica, sans-serif;
}
.ft-dsn .w-left .n b,
.ft-dsn .w-right .n b {
  margin-right: 12px;
  font-family: Arial, Microsoft Yahei, Helvetica, sans-serif;
}
.ft-dsn .w-left .n b,
.ft-dsn .w-right .n b {
  margin-right: 8px;
}
.ft-dsn .select-ul li div,
.ft-dsn .ul-row1 li div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}
.ft-dsn .w-left .c,
.ft-dsn .w-left .n,
.ft-dsn .w-left .r,
.ft-dsn .w-right .c,
.ft-dsn .w-right .n,
.ft-dsn .w-right .r {
  height: 17px;
  line-height: 17px;
}
.ft-dsn .w-left .n,
.ft-dsn .w-right .n {
  font-size: 17px;
}
.ft-dsn .items {
  cursor: pointer;
}
.ft-dsn .select-ul,
.ft-dsn .select-ul li,
.ft-dsn .ul-row1 li,
.ft-dsn .ul-row1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}
.ft-dsn .select-ul li,
.ft-dsn .ul-row1 li {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 20%;
  padding: 10px 0;
}
.ft-dsn .select-ul li {
  width: 14.285%;
  height: 95.75px;
}
.ft-dsn .select-ul li,
.ft-dsn .td,
.ft-dsn .ul-row1 li {
  border: 1px solid #bdc2c7;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.ft-dsn .row3.tongtitle {
  width: 71.43%;
}
.ft-dsn .row1{
  width: 20%;
}
.ft-dsn .row1.left {
  width: 14.285%;
}
.ft-dsn .fth .td {
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-weight: 700;
  background: #e8e8e8;
}
.ft-dsn .fth {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.ft-dsn .w-left:before {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  content: attr(data-attr);
  font-size: 100px;
  color: #e0e0e0;
}
.ft-dsn .w-left {
  position: relative;
}
.ft-dsn .w-left{
  flex: 5.5;
}
.ft-dsn .w-right{
  flex: 4.5;
}
.ft-dsn .w-left,
.ft-dsn .w-right {
  /* width: calc((100% - 10px) / 2); */
  color: #787878;
}
/* 翻滩 */
.ft-dsn {
  margin-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border: 1px solid #bdc2c7;
}
</style>